<template lang="pug">
  .dashboard.view
    section
      .section-content-wrapper
        h1 Обзор
        .panels
          .panel
            .title
              .h3
                a Абоненты
              div 1052 всего
            .chart#chart-1
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {
  GET_SUBSCRIPTIONS_STAT
} from '@/store/types'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default {
  data(){
    return {
      subscriptions_chart_data: [],
    }
  },
  computed: {
    ...mapGetters([
      'subscriber'
    ]),
  },
  mounted(){
    this.GET_SUBSCRIPTIONS_STAT().then((data)=>{
      this.buildSubscrriptionsChart(data.map((o)=>{
        o.month = this.$moment(o.month).format('MMM')
        return o
      }))
    })
  },
  created(){
    if(!this.subscriber.hasCompany()) this.$router.push({name: 'index'})
  },
  methods: {
    ...mapActions([
      GET_SUBSCRIPTIONS_STAT,
    ]),
    buildSubscrriptionsChart(data){
      am4core.useTheme(am4themes_dark)
      am4core.useTheme(am4themes_animated)

      let chart = am4core.create("chart-1", am4charts.XYChart)
      chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

      chart.data = data
      let outsider = this._.minBy(chart.data, 'total')
      let champion = this._.maxBy(chart.data, 'total')

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.dataFields.category = "month"
      categoryAxis.renderer.minGridDistance = 40
      categoryAxis.fontSize = 13

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.min = 0
      valueAxis.max = champion.total
      valueAxis.strictMinMax = true
      valueAxis.renderer.minGridDistance = 40
      // axis break
      let axisBreak = valueAxis.axisBreaks.create()
      axisBreak.startValue = 1
      axisBreak.endValue = champion.total - 1
      //axisBreak.breakSize = 0.005

      // fixed axis break
      let d = (axisBreak.endValue - axisBreak.startValue) / (valueAxis.max - valueAxis.min)
      axisBreak.breakSize = 0.05 * (1 - d) / d // 0.05 means that the break will take 5% of the total value axis height

      // make break expand on hover
      let hoverState = axisBreak.states.create("hover")
      hoverState.properties.breakSize = 1
      hoverState.properties.opacity = 0.1
      hoverState.transitionDuration = 1500

      axisBreak.defaultState.transitionDuration = 1000

      let series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.categoryX = "month"
      series.dataFields.valueY = "total"
      series.columns.template.tooltipText = "{valueY.value}"
      series.columns.template.tooltipY = 0
      series.columns.template.strokeOpacity = 0

      // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
      series.columns.template.adapter.add("fill", function(fill, target) {
        return chart.colors.getIndex(target.dataItem.index)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  .dashboard{
    .panels{
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-gap: $padding18;

      .panel{
        @include round();

        padding: $padding14;
        background: $color--gray-dark;
        //color: $color--black;

        .title{
          //@include border(1px solid $color--gray-light, bottom);

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 $padding14 $padding14;
        }
        .chart{
          height: 250px;
        }
      }
    }

  }
</style>